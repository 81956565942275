import React from 'react';
import './Dashboard.css';
import investor from '../assets/images/mainicons/inv.jpg';
import distributor from '../assets/images/mainicons/dist.jpg';
import amc from '../assets/images/mainicons/amc.jpg';
import about from '../assets/images/mainicons/about_val.jpg';
import {RedirectLinks} from '../utils/Constants';

const Box = ({linkTo, src, alt}) => {
  return (
    <div
      style={{
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}>
      <div
        style={{
          backgroundColor: '#fff',
          width: 220,
          height: 180,
          margin: 10,
          borderRadius: 10,
        }}
        className="hoverable">
        <a
          href={linkTo}
          style={{
            textDecoration: 'none',
            color: 'white',
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'center',
            height: '100%',
          }}>
          <img
            src={src}
            alt={alt}
            width={200}
            height={alt === 'About Validus' ? 140 : 170}
            style={{
              objectFit: 'contain',
            }}
          />
        </a>
      </div>
      <div style={{color: 'white'}}>{alt}</div>
    </div>
  );
};

const Dashboard = () => {
  return (
    <div
      style={{
        height: '100vh',
        display: 'flex',
        flexDirection: 'column',
        justifyContent: 'center',
        alignItems: 'center',
      }}
      className="background">
      <div
        style={{
          textAlign: 'center',
          fontSize: 20,
          marginBottom: 400,
          color: 'white',
        }}>
        Solutions for Alternates @ Validus Fintech
      </div>
      <div
        style={{
          display: 'flex',
          justifyContent: 'space-evenly',
          alignItems: 'center',
          position: 'fixed',
          width: '100%',
        }}>
        <Box
          linkTo={RedirectLinks.investor}
          src={investor}
          alt="Investor Services"
        />
        <Box
          linkTo={RedirectLinks.distributor}
          src={distributor}
          alt="Distributor Services"
        />
        <Box linkTo={RedirectLinks.amc} src={amc} alt="AMC Services" />
        <Box
          linkTo={RedirectLinks.aboutVal}
          src={about}
          alt="About Validus Fintech"
        />
      </div>
    </div>
  );
};

export default Dashboard;
